import { ErrorSeverity } from '@home-in/models'
import { NextPage } from 'next'
import React from 'react'
import { ContentWrapper } from '@elements/content-wrapper'
import { Error as ErrorElement } from '@elements/error'

const NotFound: NextPage = () => {
  const error = new Error('404')

  return (
    <ContentWrapper>
      <ErrorElement
        error={error}
        stackTrace={error.stack}
        severity={ErrorSeverity.WARNING}
        code="404"
        message="Don’t worry, you didn't break the internet! But we can't find the page you're looking for."
      />
    </ContentWrapper>
  )
}

export default NotFound
